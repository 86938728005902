.tabs {
  margin-left: -@padding-page;
  margin-right: -@padding-page;

  .card_tab_navs {
    padding-left: @padding-page;
    padding-right: @padding-page;
  }
}

@primary-color: #4394fc;@body-background: #fff;@tooltip-bg: rgba(0, 0, 0, 0.9);@tooltip-max-width: 500px;