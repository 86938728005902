@import '~antd/lib/style/themes/default.less';

.app-layout.ant-layout {
  height: 100vh;
}

.app-header.ant-layout-header {
  padding: 0 @padding-md;
  background: #fff;

  .title {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: @layout-header-background;
  }
}

.app-sidebar {
  .logo {
    height: @layout-header-height;
    padding: @padding-sm;

    img {
      width: 100%;
    }
  }
}

.app-content {
  padding: @padding-md;
  overflow: scroll;

  .container {
    min-height: 15em;
    background: #fff;
  }

  .ant-table-wrapper {
    overflow: auto;
  }
}

.app-loader {
  width: 100%;
  height: 100vh;
}

@primary-color: #4394fc;@body-background: #fff;@tooltip-bg: rgba(0, 0, 0, 0.9);@tooltip-max-width: 500px;