@import '~antd/lib/style/themes/default.less';

/* layout */

.container {
  margin: @margin-md auto;
  padding: @padding-md;
}

.flex-box {
  display: flex;
}

.vertical-flex-box {
  display: flex;
  flex-direction: column;
}

.hv-center {
  justify-content: center;
  align-items: center;
}

.flex-end {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between;
}

// margin-bottom for section layout
.mgb-md {
  margin-bottom: @margin-md;
}

/* utils */

.pointer {
  cursor: pointer;
}

// align
.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

// typography
.word-break {
  word-wrap: break-word;
  word-break: break-word; /* webkit/blink browsers */
}

// reset
ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}
