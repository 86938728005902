.tableWithFilterContainer {
  :global {
    .ms-DetailsHeader {
      padding-top: 0;
    }

    .ant-input-affix-wrapper {
      border: 0;
      box-shadow: none;
      outline: 0;
    }
  }
}

@primary-color: #4394fc;@body-background: #fff;@tooltip-bg: rgba(0, 0, 0, 0.9);@tooltip-max-width: 500px;